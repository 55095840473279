<template>
  <CornerBase :online="props.online">

    <template #give="{ givePoint, getPoints, actionRequired, removePoint }">
      <div class="row">
        <button class="col-3 vph-1 wb red give-point push-feedback" @click="givePoint(true, 1)">{{ removePoint ? '-' : '' }}{{ t('red')}}</button>
        <div class="col-6 vph-1" id="menu-container" :class="{'actionRequired': actionRequired}">
          <CornerBaseMenuTrigger>
            <button @click="getPoints" class="vpw-2 vph-1" id="corner-base-menu-trigger-button">
              <img :src="data.selfUrl + '/static/jirugi_logo_light.svg'" id="logo" alt="Jirugi">
            </button>
          </CornerBaseMenuTrigger>
        </div>
        <button class="col-3 vph-1 wb blue give-point push-feedback" @click="givePoint(false, 1)">{{ removePoint ? '-' : '' }}{{ t('blue') }}</button>
      </div>
    </template>

    <template #view="{ points, redTot, blueTot }">
      <div class="row top-color">
        <div class="col-6 red"></div>
        <div class="col-6 blue"></div>
      </div>

      <div class="text-center">

        <!-- For each round (pattern) -->
        <div class="row points-row">
          <div class="col-12 point-label">Pattern 1</div>
        </div>
        <div class="row">
          <div class="col-6">Red</div>
          <div class="col-6">Blue</div>
        </div>

        <div class="row points-row">
          <div class="col-12 point-label">Total</div>
        </div>
        <div class="row">
          <div class="col-6">Red</div>
          <div class="col-6">Blue</div>
        </div>

        <!-- This is temporary -->
        <br /><br /><br /><br /><br />
        <div class="points-row">
          <div class="small">{{ points }}</div>
          <div class="point-label">{{ redTot }} - {{ blueTot }}</div>
        </div>

      </div>
    </template>

  </CornerBase>
</template>

<script setup>
import CornerBase from "@/Components/CornerBase.vue";
import CornerBaseMenuTrigger from "@/Components/CornerBaseMenuTrigger.vue";
import {reactive} from "vue";
import {useI18n} from "vue-i18n";

const props = defineProps(['online'])
const {t} = useI18n()
const data = reactive({
  selfUrl: SELF_URL,
})

</script>
