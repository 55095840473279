<template>
  <CornerBase :online="props.online">

    <template #give="{ givePoint, getPoints, actionRequired, removePoint }">
      <div class="row">
        <button class="col-3 vph-2 wb red give-point push-feedback" @click="givePoint(true, 1)">{{ removePoint ? '-' : '' }}1</button>
        <div class="col-6 vph-2" :class="{'actionRequired': actionRequired}">
          <CornerBaseMenuTrigger>
            <button @click="getPoints" class="vpw-2 vph-2" id="corner-base-menu-trigger-button">
              <img :src="data.selfUrl + '/static/jirugi_logo_light.svg'" id="logo" alt="Jirugi">
            </button>
          </CornerBaseMenuTrigger>
        </div>
        <button class="col-3 vph-2 wb blue give-point push-feedback" @click="givePoint(false, 1)">{{ removePoint ? '-' : '' }}1
        </button>
        <button class="col-3 vph-2 wb red give-point push-feedback" @click="givePoint(true, 2)">{{ removePoint ? '-' : '' }}2
        </button>
        <button class="col-3 vph-2 wb red give-point push-feedback" @click="givePoint(true, 3)">{{ removePoint ? '-' : '' }}3
        </button>
        <button class="col-3 vph-2 wb blue give-point push-feedback" @click="givePoint(false, 3)">{{ removePoint ? '-' : '' }}3
        </button>
        <button class="col-3 vph-2 wb blue give-point push-feedback" @click="givePoint(false, 2)">{{ removePoint ? '-' : '' }}2
        </button>
      </div>
    </template>

    <template #view="{ points, deductions, pointComponent, redTot, blueTot }">
      <div class="row top-color">
        <div class="col-6 red"></div>
        <div class="col-6 blue"></div>
      </div>

      <div class="text-center">
        <div v-for="n in [1, 2, 3]" class="row align-items-center points-row">
          <div class="col-2 small">{{ pointComponent("red", n) }}</div>
          <div class="col-2">{{ pointComponent("red", n) * n }}</div>
          <div class="col-4 small point-label">{{ n }}</div>
          <div class="col-2">{{ pointComponent("blue", n) * n}}</div>
          <div class="col-2 small">{{ pointComponent("blue", n) }}</div>
        </div>

        <div class="row align-items-center points-row">
          <div class="col-2 small"></div>
          <div class="col-2">{{ -deductions.red }}</div>
          <div class="col-4 small point-label">{{ t("deductions") }}</div>
          <div class="col-2">{{ -deductions.blue }}</div>
          <div class="col-2 small"></div>
        </div>

        <div class="row align-items-center points-row">
          <div class="col-1 small"></div>
          <div class="col-3">
            <span class="result" :class="{'red-winner': redTot > blueTot}">
            {{ redTot }}
          </span>
          </div>
          <div class="col-4 small point-label">{{ t("total") }}</div>
          <div class="col-3">
            <span class="result" :class="{'blue-winner': blueTot > redTot}">
              {{ blueTot }}
            </span>
          </div>
          <div class="col-1 small"></div>
        </div>
      </div>
    </template>

  </CornerBase>
</template>

<script setup>
import {reactive} from "vue"
import CornerBaseMenuTrigger from "@/Components/CornerBaseMenuTrigger";
import CornerBase from "@/Components/CornerBase";
import {useI18n} from "vue-i18n";

const props = defineProps('online')
const {t} = useI18n()
const data = reactive({
  selfUrl: SELF_URL,
})
</script>
